.review-box {
    /*width: 80%;*/
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    /*flex-wrap: nowrap;*/
    justify-content: center;
    align-items: center;
    /*overflow: hidden;
    position: relative;*/
    margin-bottom: 20px;
    --align-self: center;
    border-radius: 10px;
    box-shadow: 15px 8px 14px 0px rgba(0, 0, 0, 0.22);
  }
  .star-img {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
  }
  .review-pic {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    margin: 10px;
  }
  .review-author {
    color: #313131;
    font-family: "sora", Sans-serif;
    font-size: 1em;
    font-weight: 400;
    padding: 5px;
    color: black;
    margin-left: 10px; /* Adiciona um espaço à esquerda entre a imagem e o autor */
  }
  .review-text {
    color: #313131;
    font-family: "sora", Sans-serif;
    font-size: 1.4em;
    font-weight: 400;
    width: 100%;
    text-align: center;
    /* topo | direita | inferior | esquerda */
    margin: 20px 10px 0 10px;
    word-wrap: break-word;
    box-sizing: border-box;
    overflow-wrap: break-word; /* Adicione esta linha para quebrar o texto conforme necessário */
  }
  
  .star-rating {
    font-size: 24px;
    align-items: center; /* Centraliza verticalmente os elementos filhos */
  }
  .star-symbol {
    display: inline-block; /* Exibe os elementos em linha */
    align-items: center; /* Centraliza verticalmente os elementos filhos */
    /* topo | direita | inferior | esquerda */
    margin: 0 0 20px 0;
  }
