html {
  font-family: 'Sora','Segoe UI', Tahoma, Geneva, Verdana, sans-serif, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
body {
  display: block;
  margin: 0px;
  background: #f0f0f2;
  padding: 0px 0px 0px 0px;
  /* topo | direita | inferior | esquerda */
  
}

.steps_wrapper {
  width: 100%;
  margin: 0px 0;
  background: #f1f1f2;
  /* topo | direita | inferior | esquerda */
  padding: 0px 0 20px 0;
  text-align: center;
}

.step {
  text-align: center;
  margin-bottom: 0px;
  margin: 0px 20px 0px 20px;
}

.steps_wrapper h1 {
  text-align: center;
}

.navigation {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.star-logo-img {
  text-align: center;
  box-sizing: border-box;
  vertical-align: middle;
  display: inline-block;
  max-width: 100%; /* Define o limite máximo como 100% da largura do contêiner pai */
  height: auto; /* Mantém a proporção da imagem */
}

.step_two_opt {
  display: flex;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 0.3em;
  /* topo | direita | inferior | esquerda */
  padding: 2% 0 2% 0;
}

/* Estilos para dispositivos móveis (largura máxima de 767px) */
@media (max-width: 767px) {
  .step_two_opt {
    flex-direction: column; /* Altera a direção do flex para coluna */
    align-items: center; /* Centraliza os itens na coluna */
    padding: 2% 0; /* Ajusta o espaçamento superior e inferior */
  }
}

.step_two {
  display: flex;
  max-width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  /* topo | direita | inferior | esquerda */
  padding: 2% 2% 2% 2%;
}

.form {
  align-self: center;
  max-width: calc(100% - 2em);
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: #313131;
  border-radius: 10px 10px 10px 10px;
  /* topo | direita | inferior | esquerda */
  padding: 2% 2% 2% 2%;
  gap: 0.5em;
}

.form label {
  font-size: 13px;
  padding-top: 15px;
  margin-bottom: 4px;
}

.form input {
  padding: 5px;
}

.steps_data {
  display: flex;
  justify-content: space-evenly;
}

ul {
  text-align: left;
}


.depoimentos_box {
  /*display: flex;
  justify-content: space-evenly;*/
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;
}

@media only screen and (min-width: 900px) {
  .depoimentos_box {
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
}
@media only screen and (max-width: 900px) {
  /*.menu {
    width: 20%;
  }*/
  .depoimentos_box {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
}

/*.highlighted-button {
  font-size: 16px;
  padding: 10px 20px;
  border: 2px solid;
  animation: changeColor 2s infinite alternate;
}*/

@keyframes changeColor {
  0% {
    background-color: red;
    color: white;
  }
  50% {
    background-color: blue;
    color: white;
  }
  100% {
    background-color: green;
    color: white;
  }
}

/* ProgressComponent.css */
.progress-container {
  width: 100%;
  max-width: 100%;
  margin: 0px auto;
  background-color: #313131;
  border: 0px solid #ccc;
  /* topo | direita | inferior | esquerda */
  padding: 20px 0 20px 0;
  overflow: hidden;
  display: flex; /* Adicionado para centralização horizontal */
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
}

.text-element-progress {
  font-family: "sora", Sans-serif;
  font-size: 1.1em;
  font-weight: 400;
  /*background-color:#69727d;*/
  color:#fff;
  /*color:#69727d;*/
  /*border:3px solid;*/
  background-color:transparent;
  margin-top:8px;
  padding-bottom: 20px;
  /*width:1em;*/
  height:1em;
  /*float:left;*/
  text-align:center;
  line-height:1;
  /*font-size:50px;*/
  display:block;
}

.progress-bar {
  width: 30%; /* Use a variável --progress-percent para definir a porcentagem */
  max-width: 30%;
  height: 30px;
  border-radius: 10px;
  justify-content: center;
  text-align: right;
  padding-right: 20px;
  padding-left: 20px;
  line-height: 30px;
  color: white;
  background-color: #545dc6;
  display: block;
  /*transition: width 10s ease-in-out;  Adicione uma transição à largura */
  display: flex;
  justify-content: space-between; /* Adicione um espaço entre os elementos internos */
  align-items: center; /* Centralize verticalmente os elementos internos */
}
.progress-bar-text {
  margin-right: 20px; /* Adicione um espaço de 20px entre o texto e a barra preenchida */
}

.intro-text {
  color: #313131;
  font-family: "sora", Sans-serif;
  font-size: 2.25em;
  font-weight: 700;
}
.logo-text {
  width: fit-content;
  font-family: "sora", Sans-serif;
  font-size: 0.8em;
  font-weight: 700;
  background-color: #545cc7;
  padding: 5px 6px;
  color: white;
  box-sizing: border-box;
  border-radius: 10px;
  text-align: center;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.intro-sub-text {
  text-align: center;
  color: #313131;
  font-family: "sora", Sans-serif;
  font-size: 0.5em;

}

.botao_avancar {
    font-family: "sora", Sans-serif;
    font-size: 1.1em;
    font-weight: 700;
    letter-spacing: 0.1em;
    background-color: #545CC7;
    border-radius: 10px 10px 10px 10px;
    padding: 20px 80px 20px 80px;
    color: #fff;
    width: 8em;
    box-shadow: none;
    text-decoration: none;
    display: inline-block;
    line-height: 1;
    box-sizing: border-box;
    border: none;
}
button {
  /*padding: 5px 15px;
  margin: 2px;*/
  font-family: "sora", Sans-serif;
  font-size: 1.1em;
  font-weight: 700;
  letter-spacing: 0.1em;
  background-color: #545CC7;
  border-radius: 10px 10px 10px 10px;
  padding: 0px 0px 0px 0px;
  width: 4em;
}

.birth-field {
  width: 100%;
  max-width: 100%;
  font-family: "sora", Sans-serif;
  font-size: 1.1em;
  font-weight: 700;
  letter-spacing: 0.1em;
  /* topo | direita | inferior | esquerda */
  padding: 20px 0 20px 0;
  overflow: hidden;
  display: flex; /* Adicionado para centralização horizontal */
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  gap: 20px;
}

/* Estilos para dispositivos móveis (largura máxima de 767px) */
@media (max-width: 767px) {
  .birth-field {
    flex-direction: column; /* Altera a direção do flex para coluna */
    align-items: center; /* Centraliza os itens na coluna */
    padding: 20px 0; /* Ajusta o espaçamento superior e inferior */
  }
  
  .birth-field .MuiTextField-root {
    width: 100%; /* Define a largura total para os campos de entrada */
    margin-bottom: 20px; /* Adiciona espaço entre os campos de entrada na vertical */
  }
  
  .birth-field label {
    transform: none; /* Restaura a transformação vertical padrão */
    text-align: center; /* Centraliza o texto horizontalmente */
  }
}

.flex-step-result {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  /* topo | direita | inferior | esquerda */
  padding: 5em 0 2em 0;
  gap: 20px;
}

.name-step-result {
  text-align: center;
  color: #DAA520;
  font-family: "sora", Sans-serif;
  font-size: 1.8em;
  font-weight: 700;
}

.text-step-result {
  width: 20em;
  text-align: center;
  color: #DAA520;
  font-family: "sora", Sans-serif;
  font-size: 1.2em;
  font-weight: 700;
  line-height: 1.4em;
  box-sizing: border-box;
  margin-bottom: 0px;
  display: block;
}

.flex-step-result-3 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  /* topo | direita | inferior | esquerda */
  padding: 0px 0 0px 0;
  gap: 0px;
}

.text-step-3 {
line-height: 1.5;
text-align: center;
color: #313131;
font-family: "sora", Sans-serif;
font-size: 1.2em;
font-weight: 400;
margin-block-start: 0;
margin-block-end: .9rem;
box-sizing: border-box;
}

.text-step-4 {
  line-height: 1.5;
  text-align: center;
  color: #313131;
  font-family: "sora", Sans-serif;
  font-size: 1.2em;
  font-weight: 400;
  margin-block-start: 0;
  margin-block-end: .9rem;
  box-sizing: border-box;
  }

  .img-corrente {
    color: #333;
    text-align: center;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    max-width: 100%; /* Define o limite máximo como 100% da largura do contêiner pai */
    height: auto; /* Mantém a proporção da imagem */
  }

.flex-step-result-4 {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.text-step-5 {
  font-family: "sora", Sans-serif;
  width: 35%;
  font-size: 1.2em;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  text-align: center;
  color: #313131;
}

.svg-x {
width: 2.5%;
height: 2.5%;
}

.text-step-6 {
  font-family: "sora", Sans-serif;
  font-size: 0.9em;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  text-align: center;
  color: #313131;
gap: 1em;
}

.flex-box-6 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0px;
  /* topo | direita | inferior | esquerda */
  padding: 0.4em 0 0px 0;
}

.flex-box-7 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1em;
  width: 100%;
  /* topo | direita | inferior | esquerda */
  padding: 1em 0 1em 0;
}

.text-step-7 {
  width: 35%;
  font-family: "Sora", sans-serif;
  font-size: 1.2em;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  text-align: center;
  color: #313131;
  gap: 1em;
}

.box-step-4 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: auto;
  /* topo | direita | inferior | esquerda */
  padding: 1em 1em 1em 1em;
}

.box-step-4-config {
  width: auto;
  max-width: 60em;
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 15px 8px 14px 0px rgba(0, 0, 0, 0.22);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 1em;
  /* topo | direita | inferior | esquerda */
  padding: 1em 0em 1em 0em;
}

.box-step-4-text-1 {
  color: #545CC7;
  font-family: "sora", Sans-serif;
  font-size: 2em;
  font-weight: 700;
}

.box-step-4-text-2 {
  text-align: center;
  font-family: inherit;
  line-height: 1.2;
  color: inherit;
  font-size: 1.25rem;
  font-weight: bolder;
  box-sizing: border-box;
  /* topo | direita | inferior | esquerda */
  padding: 0px 1em 0px 1em;
}

.box-step-4-text-3 {
  text-align: center;
  font-family: inherit;
  line-height: 1.2;
  color: inherit;
  font-size: 1.25rem;
  box-sizing: border-box;
  /* topo | direita | inferior | esquerda */
  padding: 0px 1em 0px 1em;
}

.title-triangle {
  text-align: center;
  margin-block-start: .5rem;
  margin-block-end: 1rem;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  line-height: 1;
  color: #313131;
  font-family: "sora", Sans-serif;
  font-size: 2em;
  font-weight: 700;
}

.box-triangle {
  max-width: 100vw; /* 100% da largura da tela */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 1.5em;
  overflow:auto;
}

.triangle-preview {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.button-triangle-preview {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 1em;
  position: relative; /* Adicione position: relative */
}

.table-zoom {
  font-size: 0.5em;
  /*display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;*/
  /*gap: 15px;*/
  max-width: 90vw; /* 100% da largura da tela */
  /*background-color: #FFFFFF;
  border-radius: 10px;*/
  /*box-shadow: 15px 8px 14px 0px rgba(0, 0, 0, 0.22);*/
  display: inline-block;
  /* topo | direita | inferior | esquerda */

}

.sequencia-negativa-preview {
  text-align: center;
  font-family: inherit;
  line-height: 1.2;
  color: inherit;
  position: relative;
  font-size: 1.25rem;
  box-sizing: border-box;
  /* topo | direita | inferior | esquerda */
  padding: 1em 1em 1em 1em;
  width: 80%;
}

.box-step-4-2 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.box-final {
  width: 25%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 0px;
  /* topo | direita | inferior | esquerda */
  padding: 1em 1em 1em 1em;

  line-height: 1.5;
  text-align: center;
  color: #000000;
  font-family: "sora", Sans-serif;
  font-size: 1em;
  font-weight: 400;
  margin-block-start: 0;
  margin-block-end: .9rem;
  box-sizing: border-box;
}
